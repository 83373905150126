import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ModalDataType } from './types'
import cn from 'classnames';
import { useTheme } from '../../../contexts/ThemeContext';
import s from './Modal.module.scss';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../../utils/responsiveBreakpoints';
const style = (theme: string) => (theme == 'dark' ? {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: '#252525',
    color: '#fff',
    fontFamily: "'Avenir', sans-serif",
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
} : {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: '#eeecf1',
    color: '#000',
    fontFamily: "'Avenir', sans-serif",
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
});
const mediaStyle = (theme: string) => (theme == 'dark' ? {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: '#252525',
    color: '#fff',
    fontFamily: "'Avenir', sans-serif",
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
} : {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: '#eeecf1',
    color: '#000',
    fontFamily: "'Avenir', sans-serif",
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
});
export interface ModalProps {
    // radioGroupLabel?: string;
    // optionList: RadioButtonDataType[];
    // onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // value: string | number | boolean;
    // row?: boolean;
    open: boolean;
    setOpen: (value: React.SetStateAction<boolean>) => void
    width?: string;
    containerStyle?: any;
    isSuccess: boolean;
    isFailed: boolean;
    header: string;
    message: string;
    subMessage?: string;
    action?: React.ReactNode;
}

const CustomizedModal: React.FC<ModalProps> = ({
    open,
    setOpen,
    width = '70%',
    containerStyle,
    isSuccess = false,
    isFailed = false,
    header,
    message,
    subMessage,
    action = null,
}: ModalProps) => {
    const { theme } = useTheme();
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={isMobile || isTablet ? mediaStyle(theme):style(theme)} className={s.modalContainer}>
                <div className={s.closeButton}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon sx={theme == 'dark'? {color: 'white'}:{color: 'black'}}/>
                    </IconButton>
                </div>
                <div className={s.content}>
                    {isSuccess ? 
                    <svg className={s.icon} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className={cn(s.path, s.circle)} fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <polyline className={cn(s.path, s.check)} fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg>
                    : null}
                    {isFailed ? 
                    <svg className={s.icon} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className={cn(s.path, s.circle)} fill="none" stroke="#f33" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <line className={cn(s.path, s.line)} fill="none" stroke="#f33" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                        <line className={cn(s.path, s.line)} fill="none" stroke="#f33" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                    </svg>
                    : null}
                    <h2 className={theme === 'dark' ? s.dark: s.light}>
                        {header}
                    </h2>
                    {/* {isSuccess? <h2 className={theme === 'dark' ? s.dark: s.light}>
                        Login Successful
                    </h2> : null}
                    {isFailed? <h2 className={theme === 'dark' ? s.dark: s.light}>
                        Login Failed
                    </h2> : null} */}
                    <div className={theme === 'dark' ? cn(s.description, s.dark): cn(s.description, s.light)}>
                        {message}
                    </div>
                    {subMessage? <div className={theme === 'dark' ? cn(s.description, s.dark): cn(s.description, s.light)}>
                        {subMessage}
                    </div> : null}
                    {action ? action : null}
                    {/* {isSuccess?<div className={theme === 'dark' ? cn(s.description, s.dark): cn(s.description, s.light)}>
                        An E-mail will be sent with a link to the email address you just provided.
                    </div>: null}
                    {isFailed?<div className={theme === 'dark' ? cn(s.description, s.dark): cn(s.description, s.light)}>
                        Failed to send the OTP link to the Email you provided, please try again later.
                    </div>: null} */}
                </div>
                
            </Box>
        </Modal>
    )
};

export default CustomizedModal;
